/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles } from '@material-ui/core';

import Header from '../../../components/layout/Header';
import Footer from '../../../components/layout/Footer';
import SeoFields from '../../../components/page/SeoFields';
import HeroImage from '../../../components/page/HeroImage';
import Title from '../../../components/page/Title';
import Content from '../../../components/page/Content';
import RelatedPages from '../../../components/page/RelatedPages';
import BrandPages from '../../../components/page/BrandPages';
import ShortDescription from '../../../components/page/ShortDescription';
import CategoriesAndPublishingDate from '../../../components/page/CategoriesAndPublishingDate';
import ReactAndShare from '../../../components/page/ReactAndShare';
import SiteNotification from '../../../components/page/SiteNotifications';
import AnalyticsModule from '../../../components/module/AnalyticsModule';
import withSecondaryTheme from '../../../components/shared/ThemeStyle/withSecondaryTheme';
import PlayableIframeScript from '../../../components/page/PlayableIframeScript';
import PageBrowseTracker from '../../../components/page/PageBrowseTracker';
import TriggerBeeBanner from '../../../components/shared/TriggerBeeBanner';

import useInitI18next from '../../../components/hooks/useInitI18next';
import useInitLuxon from '../../../components/hooks/useInitLuxon';

const styles = theme => ({
  root: {
    display: 'block',
    ...theme.palette.pageTemplates.otherPageTemplates,
  },
});

function ArticlePageTemplate({ classes, data }) {
  const pageData = get(data, 'contentfulArticlePage');
  const analytics = get(pageData, 'mainTheme.analytics');

  useInitI18next();
  useInitLuxon();

  return (
    <div className={classes.root}>
      <PageBrowseTracker pageData={pageData} />
      <SeoFields pageData={pageData} />
      <PlayableIframeScript pageData={pageData} />
      <Header pageData={pageData} />
      <AnalyticsModule data={analytics} />
      <main>
        <SiteNotification pageData={pageData} />
        <TriggerBeeBanner pageData={pageData} />
        <Title pageData={pageData} />
        <ShortDescription pageData={pageData} />
        <HeroImage pageData={pageData} />
        <Content pageData={pageData} />
        <CategoriesAndPublishingDate pageData={pageData} />
        <ReactAndShare pageData={pageData} />
        <RelatedPages pageData={pageData} />
        <BrandPages pageData={pageData} />
      </main>
      <Footer pageData={pageData} />
    </div>
  );
}

ArticlePageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

ArticlePageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export const pageQuery = graphql`
  query ArticlePageTemplate($pageId: String!) {
    contentfulArticlePage(id: { eq: $pageId }) {
      id
      contentful_id
      title
      menuTitle
      shortDescription
      fullPath
      publishingDate
      languageCode
      publishingDate
      internal {
        type
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      seoFields {
        ...SeoDataFragment
      }
      primaryChannel {
        ...PrimaryChannelFragment
      }
      searchPage {
        ...SearchPageFragment
      }
      breadcrumbs {
        ...BreadcrumbFragment
      }
      topNavigation {
        ...TopNavigationFragment
      }
      heroImage {
        ...HeroImageFragment
      }
      content {
        ...ContentFragment
      }
      categories {
        ...CategoryFragment
      }
      relatedPages {
        ...RelatedPageFragment
      }
      brands {
        ...BrandPageFragment
      }
      mainTheme {
        ...themeFragment
      }
    }
  }
`;

export default withSecondaryTheme(withStyles(styles)(ArticlePageTemplate));
